.powered {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > label {
    opacity: 0.75;
    margin-bottom: 4px;
    font-size: 12px;
  }

  > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 96px;

    img {
      width: 100%;
    }
  }
}
