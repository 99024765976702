@use "./tailwind";

html {
  @apply bg-traplife;
}

.app {
  @apply w-full mx-auto my-4 flex flex-col items-center gap-4 px-2;
  max-width: 750px;

  * {
    &:focus,
    &:active {
      @apply border-slate-400 outline-slate-400 outline-offset-0 outline-1;
    }
  }
}
