.main-text {
  @apply text-white text-center;
  max-width: 500px;
}

form {
  @apply w-full flex flex-col gap-6;
  max-width: 500px;

  .text-input {
    @apply relative w-full;

    h3 {
      @apply font-semibold text-white;
    }

    input,
    textarea {
      @apply w-full rounded-md my-1 resize-y;
    }

    textarea {
      @apply h-32;
    }

    &-error {
      input,
      textarea {
        @apply border-red-500 border-2;
      }
    }
  }

  .text-input,
  .tos-wrapper {
    span {
      @apply text-red-500 text-sm absolute left-0 -bottom-4;
    }
  }

  .tos-wrapper {
    @apply relative text-white;
    .tos-check {
      @apply flex gap-2 items-center font-semibold ring-transparent;

      #tos {
        &:focus,
        &:active {
          @apply ring-transparent;
        }
      }

      input {
        @apply rounded-sm;
      }
    }
  }

  .submit-button {
    @apply py-2 w-44 self-center bg-white text-black rounded-md flex justify-center items-center;
  }
}
